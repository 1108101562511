import { DatatableInit } from "./init"
// import { DEFAULT_OPTIONS } from "./init"
// import { DEFAULT_COLUMNDEFS } from "./init"

export class UsersDatatable extends DatatableInit {
  // customize datatables for users dataset
  constructor () {

    let columndefs = [
      {
        targets: [5],
        className: "text-center"
      },
      {
        targets: [-1, -2],
        orderable: false,
        searchable: false,
        className: "text-center"
      },
      {
        targets: [-3],
        searchable: false
      }
    ]
    super("users_table", columndefs)
  }
}

