import { DatatableInit } from "./init"
import { DEFAULT_OPTIONS } from "./init"
// import { DEFAULT_COLUMNDEFS } from "./init"

export class VisitsDatatable extends DatatableInit {
  // customize datatables for visits dataset
  constructor () {
    let columndefs = [
      {
        targets: [3, 4, 5, 6, 7],
        orderable: false,
        searchable: false,
        className: "text-center"
      }
    ]
    let options = Object.assign({}, DEFAULT_OPTIONS)
    options.order = [[0, "desc"]]
    super("visits_table", columndefs, options)
  }
}

