import { DatatableInit } from "./init"
// import { DEFAULT_OPTIONS } from "./init"
// import { DEFAULT_COLUMNDEFS } from "./init"

export class WorkContactsDatatable extends DatatableInit {
  // customize datatables for work_templates dataset
  constructor () {
    let columndefs = [
      {
        targets: [2],
        orderable: false,
        searchable: true
      },
      {
        targets: [-1, -2],
        orderable: false,
        searchable: false,
        className: "text-center"
      }
    ]
    super("work_templates_table", columndefs)
  }
}

