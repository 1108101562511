import { Controller } from "stimulus"
import Rails from "@rails/ujs"
export default class extends Controller {

  connect () {
    // editing
    const self = this
    $("#work_template_items").on("cocoon:after-insert", function (e, insertedItem) {
      // set focus on first input
      $(insertedItem).find("input").first().trigger("focus")
      // create a hidden field to put on last possition new created items
      const destroy = $(insertedItem).find(":input[value='false']")
      // best approach is to clone existing hidden field _destroy
      // major issue here is we cannot know temp random id for this nested field, so
      // replacing '_destroy' in both name and id is our best choice
      const sorted = destroy.clone()
      sorted
        .attr("name", self.replaceAttr(destroy, "name"))
        .attr("id", self.replaceAttr(destroy, "id"))
        .attr("value", 99999)
        .insertAfter(destroy)
    })

    // showing
    $("#sortable_work_template_items").sortable({
      update: function (e, ui) {
        Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable("serialize")
        })
      }
    })
  }

  replaceAttr (element, attr) {
    return element.attr(attr).replace(/_destroy/, "sorted")
  }

  close_modal () {
    $(this.modal).modal("hide")
  }

  get modal () {
    return this.targets.find("modal")
  }
}
