export default class extends ApplicationController {
  static targets = ["small"]

  expand () {
    new coreui.Modal(this.pictureModal).show()
    document.getElementById("expanded-picture")
            .setAttribute("src", this.smallTarget.getAttribute("data-original"))
  }

  get pictureModal () {
    let _pictureModal = document.getElementById("picture-modal")
    if (_pictureModal == null) {
      _pictureModal = document.createElement("div")
      _pictureModal.setAttribute("class", "modal")
      _pictureModal.setAttribute("id", "picture-modal")
      _pictureModal.setAttribute("data-backdrop", "static")
      _pictureModal.innerHTML = `
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <img id="expanded-picture" class="img-fluid" data-dismiss="modal">
          </div>
        </div>
      `
      const modalContainer = document.getElementById("remote_container")
      modalContainer.appendChild(_pictureModal)
    }
    return _pictureModal
  }
}

