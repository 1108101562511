import { DatatableInit } from "./init"
import { DEFAULT_OPTIONS } from "./init"
// import { DEFAULT_COLUMNDEFS } from "./init"

export class GardensDatatable extends DatatableInit {
  // customize datatables for gardens dataset
  constructor () {
    let columndefs = [
      {
        targets: [0, -2, -1],
        orderable: false,
        searchable: false,
        className: "text-center"
      }
    ]
    let options = Object.assign({}, DEFAULT_OPTIONS)
    options.order = [[1, "asc"]]
    super("gardens_table", columndefs, options)
  }
}

