import { Controller } from "stimulus"
import Rails from "@rails/ujs"
export default class extends Controller {

  static targets = ["selectTemplate"]

  connect () {
    // editing
    $("#visit_users").on("cocoon:after-insert", function (e, insertedUser) {
      // set focus on first input
      $(insertedUser).find("input").first().trigger("focus")
    })
    $("#visit_tasks").on("cocoon:after-insert", function (e, insertedTask) {
      // manually entered tasks should be done by default
      $(insertedTask).find("input[type='checkbox']").first().attr("checked", true)
      // set focus on description
      $(insertedTask).find("input[type='text']").first().trigger("focus")
    })
  }

  addTemplate () {
    const self = this
    Rails.ajax({
      url: `${self.selectTemplateTarget.getAttribute("data-url")}/${self.selectTemplateTarget.value}.json`,
      type: "get",
      dataType: "json",
      error: (_jqXHR, textStatus, errorThrown) => App.common_controller.toast(`AJAX Error: ${textStatus}`, errorThrown || "Lost connection to server", "error"),
      success: (data, _textStatus, _jqXHR) => {
        const items = data.work_template.data
        const addTaskBtn = document.getElementById("add-task-btn")
        items.forEach((item) => {
          addTaskBtn.click()
          const last = $(".nested-fields").last()
          last.find("input[type='checkbox']").attr("checked", false)
          last.find("input[type='text']").val(item.description)
        })
        addTaskBtn.focus()
      }
    })
  }

  close_modal () {
    $(this.modal).modal("hide")
  }

  get modal () {
    return this.targets.find("modal")
  }
}
