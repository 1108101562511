import { DatatableInit } from "./init"
// import { DEFAULT_OPTIONS } from "./init"
// import { DEFAULT_COLUMNDEFS } from "./init"

export class ContactsDatatable extends DatatableInit {
  // customize datatables for contacts dataset
  constructor () {
    let columndefs = [
      {
        targets: [-1, -2],
        orderable: false,
        searchable: false,
        className: "text-center"
      }
    ]
    super("contacts_table", columndefs)
  }
}

